<template>
    <va-card align="center" justify="center">
        <h1>Welcome To report page</h1>
    </va-card>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'

Vue.use(vueResource)
export default {
  name: 'report',
}
</script>